import React from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import Cookies from "js-cookie";

interface PrivateRouteProps {
  element: React.ReactNode;
  path: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element, ...rest }) => {
  const authToken = Cookies.get("accessToken");

  return (
    <Routes>
    <Route
      {...rest}
      element={authToken ? element : <Navigate to="/" />}
    />
    </Routes>
  );
};

export default PrivateRoute;
