import apiUrl from "./BTPApi";
import { BTPAuth } from "./BTPAuth";
// import errorHandling from "./ErrorHandling";

const Verifaction = {
  sendMobileOtp: async (data: object) => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.sendMobileOtpUrl,
        "post",
        {},
        data
      );
      return response.data;
    } catch {
      console.log("error");
    }
  },

  verifyMobileOtp: async (data: object) => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.verifyMobileOtpUrl,
        "post",
        {},
        data
      );
      return response.data;
    } catch {
      console.log("error");
    }
  },
};

export default Verifaction;
