import React, { KeyboardEvent, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Verifaction from "../Services/Verification";
import { useNavigate } from "react-router-dom";

interface VerifyMobileProps {
  show: boolean;
  onHide: () => void;
}

const VerifyMobile: React.FC<VerifyMobileProps> = ({ show, onHide }) => {
  const [phoneNum, setPhoneNum] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [otpSent, setOtpSent] = useState<boolean>(false);  

  const navigate = useNavigate();

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      // Call your handleFilter function or do something else here.
    }
  };

  const sendOtp = async () => {
    const response = await Verifaction.sendMobileOtp({
      phone_number: phoneNum
    });
    response.status && setOtpSent(true);    
  };

  
  const verifyOtp = async () => {
    const response = await Verifaction.verifyMobileOtp({
      phone_number: `91${phoneNum}`,
      otp: otp,
    });    
    if (response.status) {
      onHide();
      navigate('/onboarding');
    }
  };

  return (
    <Modal size="sm" show={show} onHide={onHide} centered>
      <Modal.Body className="">
        <Form>
          {otpSent ? (
            <>
              <Form.Group>
                <Form.Label>Enter Otp:</Form.Label>
                <Form.Control
                  type="text"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  className="form-control"
                  onKeyDown={handleKeyPress}
                />
              </Form.Group>
              <Button className="w-100 my-3" onClick={verifyOtp}>
                Verify Otp
              </Button>
            </>
          ) : (
            <>
              <Form.Group>
                <Form.Label>Enter Phone Number:</Form.Label>
                <Form.Control
                  type="text"
                  value={phoneNum}
                  onChange={(e) => setPhoneNum(e.target.value)}
                  className="form-control"
                  onKeyDown={handleKeyPress}
                />
              </Form.Group>
              <Button className="w-100 my-3" onClick={sendOtp}>
                Send Otp
              </Button>
            </>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default VerifyMobile;
