import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import logo from './logo.svg';
import './App.css';
import Header from './Layout/Header';
import AppMain from './Layout/AppMain';

function App() {
  return (
    <div className="App">          
      <AppMain />
    </div>
  );
}

export default App;
