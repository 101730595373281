import { Button, Container, Nav, Navbar } from "react-bootstrap";
import Logo from "../Assets/img/brandlabs-logo.png";
import handleLogin from "../Utils/HandleLogin";
import handleLogout from "../Utils/HandleLogout";
import { useState } from "react";
import Cookies from "js-cookie";
import VerifyMobile from "../Components/VerifyMobile";

const Header = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [show, setShow] = useState(false);
  const handleLoginResult = () => {
    handleLogin();
    setTimeout(() => {
      const token = Cookies.get("accessToken");
      console.log("token login", token);
      token && setIsLoggedIn(true);
      token && setShow(true);
    },4500);
  };

  const handleLogoutResult = () => {
    handleLogout();
    const token = Cookies.get("accessToken");
    console.log("token logout", token);
    !token && setIsLoggedIn(false);
  };

  return (
    <>
    <Nav>
      <Container className="d-flex justify-content-between align-items-center py-3">
        <Navbar.Brand href="/">
          <img src={Logo} alt="BrandLabs" className="logo" />
        </Navbar.Brand>
        {/* when design is ready create a new component to load the login button or the user profile */}
        <Nav.Item>
          {isLoggedIn ? (
            <Button onClick={handleLogoutResult} className="login-btn">
              Logout
            </Button>
          ) : (
            <Button onClick={handleLoginResult} className="login-btn">
              Login
            </Button>
          )}
        </Nav.Item>
      </Container>
    </Nav>
    <VerifyMobile show={show} onHide={() => setShow(false)} />
    </>
  );
};

export default Header;
