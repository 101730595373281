import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoute from "../Services/PrivateRoute";
import Onboarding from "../Pages/Onboarding";
import Header from "./Header";

function AppMain() {
  return (
    <Router>
      <Header />
      <div className="border-bottom"></div>
      {/* <Routes> */}
        <PrivateRoute path="/onboarding" element={<Onboarding />} />
        {/* Define your other routes here */}
      {/* </Routes> */}
    </Router>
  );
}

export default AppMain;
