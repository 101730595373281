import axios, { AxiosRequestConfig, Method } from 'axios';
import { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';

const BTPAuth = {
  async fetchData<T>(
    url: string,
    method: Method,
    headers?: AxiosRequestConfig,
    data?: any
  ): Promise<AxiosResponse<any>> {
    const headerInfo = {
      accesstoken: Cookies.get('accessToken'),
      'Content-Type': 'application/json',
    };

    if (headers) {
      headers.headers = { ...headerInfo, ...(headers.headers || {}) };
    } else {
      headers = { headers: headerInfo };
    }

    let response: AxiosResponse<any>;

    if (data) {
      if (data.params) {
        response = await axios.request<T>({
          url,
          method,
          params: data.params,
          headers: headers.headers,
        });
      } else if (method === 'delete') {
        response = await axios.request<T>({
          url,
          method,
          headers: headers.headers,
          data: data,
        });
      } else {
        response = await axios.request<T>({
          url,
          method,
          data: data,
          headers: headers.headers,
        });
      }
    } else {
      response = await axios.request<T>({
        url,
        method,
        headers: headers.headers,
      });
    }

    return response;
  },
};

export { BTPAuth };
