import Cookies from "js-cookie";

const handleLogin = (): void => {
  const popupWindow = window.open(
    `https://core.beingtheparent.com/login?apiKey=${process.env.REACT_APP_API_KEY}&redirect=1`,
    "_blank",
    "width=600,height=400"
  );

  if (popupWindow) {
    popupWindow.onload = () => {};
  } else {
    console.error("Popup blocked");
  }

  window.addEventListener("message", (event) => {
    console.log("Received message from popup:", event);

    // create a context provider here and provide all the necessary user data to it
    if (event.data?.payload?.token) {      
      Cookies.set('accessToken', event.data?.payload?.token);            
    }
    // return true;
  });
  // return false;
};

export default handleLogin;
